<template>
    <van-popup v-model:show="showLogin" @click-close-icon="close" closeable close-icon="close" round position="top"  :style="{ width: '100%' }">
        <van-form @submit="onlogin" class="fotm-sunmit">
            <van-field v-model="username" name="用户名" placeholder="请输入用户名"
                left-icon="https://aa.jscofly.cn/ueditor/file/user.png"
                :rules="[{ required: true, message: '请填写用户名' }]" />
            <van-field v-model="password" type="password" placeholder="请输入密码"
                left-icon="https://aa.jscofly.cn/ueditor/file/pwd.png"
                :rules="[{ required: true, message: '请填写密码' }]" />
            <div class="submit-button">
                <van-button block type="info" native-type="submit" class="button-s">提交</van-button>
            </div>
        </van-form>
    </van-popup>
</template>
<script>
    import {reactive, ref, toRefs, getCurrentInstance, inject, cpomputed, watch, computed} from 'vue'
    import { useRouter } from 'vue-router'

    export default {
        name: 'LoginAlert',
        props: {
            showLogin: { type: Boolean, default: false},
            isTS: { type: Boolean, default: false},
        },
        setup(props, context) {
            const { proxy } = getCurrentInstance();
            const router = useRouter();
            let pDataALL = inject('dataAll')
            const dataAll = reactive({
                username: ref(''),
                password: ref(''),
                enterpriseModel: !proxy.isTS? (ref(pDataALL.enterpriseModel)):'',
                facilityQrNumber: !proxy.isTS? '':(ref(pDataALL.facilityQrNumber))
            })

            // 登录
            const onlogin = async () => {
                await proxy.$http({
                    url: proxy.$http.adornUrl(`/app/qr/qrLogin`),
                    method: 'post',
                    data: proxy.$http.adornData({
                        'userName': dataAll.username,
                        'userPwd': dataAll.password
                    })
                }).then(({ data }) => {
                    if (data && data.code === 0) {
                        sessionStorage.setItem("userToken", data.token);
                        if(!proxy.isTS) {
                            let enterpriseModel = sessionStorage.getItem('enterpriseModel')
                            if(!enterpriseModel || enterpriseModel === 'null') {
                                //跳转选择类型
                                router.push({
                                    path: '/enterpriseChoice/' + proxy.$router.currentRoute.value.params.id
                                });
                            } else {
                                //跳转修改
                                router.push({
                                    path: '/enterpriseEditJump/' + proxy.$router.currentRoute.value.params.id
                                });
                            }
                        }
                        else {
                            let enterpriseId = sessionStorage.getItem('enterpriseId')
                            console.log(dataAll.facilityQrNumber)
                            //跳转设备修改或者扫码绑定设备
                            !enterpriseId||enterpriseId==='null'? proxy.$emit('close') : (router.push({path: '/equipmentEdit/'+ dataAll.facilityQrNumber})) 
                        }
                    } else {
                        proxy.$toast.fail(data.msg);
                    }
                })
            }
            const close = () =>{
                proxy.$emit('close', false)
            }
            return {
                ...toRefs(dataAll),
                onlogin,
                close
            }
        }
    }
</script>
<style scoped>
    .fotm-sunmit {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.submit-button {
		padding: 1rem 10% 0rem 10%;
	}
 
	::v-deep .van-field__left-icon .van-icon,
	.van-field__right-icon .van-icon {
		font-size: 24px;
	}

	::v-deep .van-field__left-icon {
		margin-right: 15px;
	}

	::v-deep .van-cell::after {
		border-bottom: 1px solid #215ED1;
	}

	.button-s {
		border-radius: .4rem;
		background-color: #215ED1;
		border-color: #215ED1;
		color: white;
		font-size: 16px;
	}
</style>